
import { Navigate, Outlet } from "react-router-dom";
import Loading from "../components/Loading";
import authService from "../services/authService";

export const ProtectedLayout = () => {
  const [user, loading] = authService.useAuth()

  if (!loading && !user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      { loading && <Loading /> }
      { user && <Outlet /> }
    </>
  )
};

