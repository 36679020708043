import { useState } from "react";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import showsService from "../services/showsService";



const ShowTaxInput = ({parentRef, taxonomy, ...rest}) => {

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const turnOffFilterBy = () => true;

  const handleSearch = async (query) => {
    setIsLoading(true)
    const res = await showsService.getShows({ taxonomy, termLike:query })
    if( res && res.data ){
      const shows = res.data
      shows.map(s => s.terms = s.terms.filter( t => t.taxonomy === taxonomy ))
      console.log(shows)

      const showOptions = shows.map(s => { return {name: s.name, term: (s.terms.find( t => t.name.indexOf(query) > -1 )?.name) } })
      console.log(shows)
      setOptions(showOptions)
      setIsLoading(false)
    }
  }

  return ( 
    <AsyncTypeahead
      filterBy={turnOffFilterBy}
      isLoading={isLoading}
      minLength={3}
      onSearch={handleSearch}
      options={options}
      allowNew 
      placeHolder="Search Theme"
      labelKey="name"
      ref={parentRef}
      renderMenuItemChildren={(option, { text }) => (
        <>
          { option && <>
            {option?.name}
            <div><small><Highlighter search={text}>{option.term}</Highlighter></small></div>
            </>
          }
        </>
      )}
      {...rest}
    />
  );
}

export default ShowTaxInput;