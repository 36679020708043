import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../services/authService";

export const HomeLayout = () => {
  const [user] = useAuth()

  if (user) {
    return <Navigate to="/admin" />;
  }

  return (
    <>
      <Outlet />
    </>
  )
};
