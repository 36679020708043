import { useState } from 'react';
import styles from './dropmenu.module.css';

const DropMenu = ({title, children}) => {
  const [open, setOpen] = useState(false);

  const handleEvent = (e) => {
    if (e.target.tagName === 'A') {
      setOpen(!open)
    }
  }

  return ( 
    <div className={`${styles.dropMenu} ${open? styles.open: ''}`}>
      <div className={styles.title} onClick={() => setOpen(!open)}>{title}</div>
      <div 
        className={styles.subMenu}
        onMouseUp={e => handleEvent(e)}>
        {children}
      </div>
    </div>
  );
}

export default DropMenu;