import { useMemo, useRef, useState } from "react";
import { Card, CardFooter, CardHeader } from "react-bootstrap";
import { Link } from "react-router-dom";
import showsService from "../services/showsService";
import { slugify } from "../util/slugify";

const ShowItem = ({name, onRemoveItem, onLaunchClick}) => {

  const loadingRef = useRef(false)
  const [showInfo, setShowInfo] = useState({
    name: name,
    network: "N/A",
    year: "N/A",
    genres: ["N/A"],
  })
  const [exists, setExists] = useState(false)
  const [statusClass, setStatusClass] = useState('status-loading')

  useMemo(() => { 
    async function fetchData() {
      const showSlug = slugify(name);
      loadingRef.current = true
      try{
        const show = await showsService.getShowBySlug( showSlug )
        if( show && show.id ){
          setExists( true )
          setStatusClass('status-success')
          setShowInfo( show )
        } else {
          setStatusClass('status-not-found')
          setExists(false)
        }
      } catch (err) {
        setStatusClass('status-failed')
        setExists(false)
      }
      
    }
    if(!loadingRef.current){ fetchData(); }
  }, [name])

  return ( 
    <Card className={ ['mb-2', statusClass].join(' ') }>
      <CardHeader className="d-flex justify-content-between">
        <div>
          { exists && <Link to={`/${ showInfo?.slug }`}><h4>{name}</h4></Link> }
          { !exists && <span>{name}</span> }
          <div className="s">{showInfo.year} / {showInfo.genres.join(', ')}</div>
        </div>
        <div>
        { exists && <button className="x" onClick={e => onLaunchClick(showInfo)}>O</button> }
          <button className="x" onClick={e => onRemoveItem(name)}>&times;</button>
        </div>
      </CardHeader>
      { exists && 
        <CardFooter className="xs">
          {showInfo.themes?.join(', ')}
        </CardFooter>
      }
    </Card>

  );
}

export default ShowItem;