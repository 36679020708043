const TextArea = ({ name, label, value, error, ...rest }) => {
  return (
    <div className="field">
      <label htmlFor={name}>{label}</label>
      <textarea
        className={ error && 'has-error'}
        defaultValue={value}
        {...rest}
        name={name}
        id={name}></textarea>
      { error && <div className="error">{error}</div>}
    </div>
  );
}

export default TextArea;