import axios from "axios";
import { getAuthHeader } from "./authService";
import logger from "./logService";

axios.defaults.headers.common['Authorization'] = '';

axios.interceptors.request.use( async config => {
  let authorization = await getAuthHeader()
  if(authorization){
    config.headers.common["Authorization"] = authorization
  }
  return config;
}, (error) => {
  return Promise.reject(error);
})

// log unexpected errors & display on toast
axios.interceptors.response.use(null, error => {

  logger.log( error );

  const expectedError = 
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if(expectedError){
    if( error.response && 
      error.response.data &&
      error.response.data.message ){
      /*toast.error( error.response.data.message );*/
      logger.log( error.response.data.message );
      logger.log( 'failed response:', error.response )
      
    } else if( error.message ){
      /*toast.error( error.message );*/
      logger.log( error.message );
    }
  }

  return Promise.reject(error);

});

const httpService = {
  get: axios.get,
  put: axios.put,
  patch: axios.patch,
  post: axios.post,
  delete: axios.delete
};

export default httpService;



