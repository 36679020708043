const PageHeader = ({title}) => {
  return ( 
    <div className="flex nw">
      <div className="fill">
        <div className='page-title'>{title}</div>
      </div>
    </div>
  );
}
 
export default PageHeader;