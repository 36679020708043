import ShowCard from "./ShowCard";

const ShowsGrid = ({shows}) => {
  return ( 
    <>
    {shows && shows.map(show => (
      <ShowCard key={show.id} show={show} />
    ))}
    </>
  );
}

export default ShowsGrid;