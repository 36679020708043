
import Form from "./common/form";
const Joi = require('joi-browser');

export default class RegisterForm extends Form {

  state = {
    data: { email: "", password: "", displayName: "" },
    errors: {}
  };

  schema = {
    email: Joi.string()
      .email()
      .min(6)
      .max(65)
      .required()
      .label('Email'),
    password: Joi.string()
      .min(8)
      .max(24)
      .required()
      .label('Password'),
    displayName: Joi.string()
      .min(5)
      .max(65)
      .required()
      .label("Full Name")
  }

  doSubmit = async () => {
    /*this.props.onSubmit( this.state.data );*/
  }

  render() {
    return (
      <form className="form" onSubmit={this.handleSubmit}>
        { this.renderInput("displayName", "Full Name") }
        { this.renderInput("email", "Email") }
        { this.renderInput("password", "Password", "password") }
        <div className="tac">
          { this.renderButton("Register") }
        </div>
      </form>
    )
  }
}




