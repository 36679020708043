import { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import showsService from "../services/showsService";

const ShowNameInput = ({parentRef, ...rest}) => {

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const turnOffFilterBy = () => true;

  const handleSearch = async (query) => {
    setIsLoading(true)
    const res = await showsService.getShows( { search: query } )
    if(res && res.data){
      const shows = res.data
      const showOptions = shows.map(s => { return { label: s.name } })
      console.log(shows)
      setOptions(showOptions)
      setIsLoading(false)
    }
  }

  return ( 
    <AsyncTypeahead
      filterBy={turnOffFilterBy}
      isLoading={isLoading}
      minLength={3}
      onSearch={handleSearch}
      options={options}
      allowNew 
      ref={parentRef}
      {...rest}
    />
  );
}

export default ShowNameInput;