import Moment from "react-moment";
import { Link } from "react-router-dom";
import { sanitizeNetworkName } from "../../util/networks";

const ShowCard = ({show}) => {
  console.log('show', show)

  let genres = show.terms.filter(term => term.taxonomy === 'genre').map(term => term.name);
  if(genres.length === 0){
    genres = show.genres;
  }
  const networkName = sanitizeNetworkName(show.network)
  console.log('ShowCard', show)

  return ( 
    <div>
      <div className="card mb-2">
        <div className="card-body">
          <Link to={`/admin/shows/${show.id}/edit`}><h4 className='card-title'>{show.name}</h4></Link>
          <div className='card-subtitle mb-2 text-muted'>{networkName} &middot; {show.year}</div>
          <div className="flex s g">
            <div className="genres">{genres?.join(', ')}</div>
            <Moment date={show.updated} fromNow />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowCard;