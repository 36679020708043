import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../App';
import DropMenu from './common/dropmenu';
import NavMenu from './NavMenu';

const Header = () => {

  return ( 
    <React.Fragment>
    <NavMenu />
    <div className="nb">
      <div className="flex aic">
        <div className="flex nw">
          <label htmlFor="dw-cb" className="dw-tgl">=</label>
          <img src="https://cdn.showslike.net/_/m/2023/02/slnet-110.png" id="logo" alt="ShowsLike" width="40" height="40" />
          <Link className="brand" to="/">ShowsLike</Link>
        </div>
        <div className='d-none d-md-flex'>
        </div>
        <div className="act">
          {renderUser()}
        </div>
      </div>
    </div>
    </React.Fragment>
  );
}

function renderUser() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { user } = useContext(UserContext);

  console.log('user', user)

  if (user && user.displayName ) {
    return (
      <div>
        <DropMenu
          title = {
            <div className="flex aic">
              <span className="name d-none d-md-inline">{user.displayName}</span>
              <img
                className="avatar"
                src={user.photoURL}
                alt={`${user.displayName}’s avatar`}
                width="40"
                height="40"
              />
            </div>
          }>
            <Link to={`/logout`} className="" >Logout</Link>
          </DropMenu>
      </div>
    );
  } else {
    return (
      <div className="">
        <Link to="/login" className="btn">Login</Link>
      </div>
    );
  }
}

export default Header;