import ShowItem from "./ShowItem";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

const SimilarShowsList = ({items, onSortUpdate, onRemoveItem, onLaunchClick}) => {

  const SortableItem = SortableElement(
      ({value}) => 
        <ShowItem 
          key={value} 
          name={value} 
          onRemoveItem={onRemoveItem} 
          onLaunchClick={onLaunchClick}
          />
    );

  const SortableList = SortableContainer(({items}) => {
    return (
      <div>
        {items.map((value, index) => (
          <SortableItem key={`item-${value}`} index={index} value={value} />
        ))}
      </div>
    );
  });

  return ( 
    <SortableList items={items} onSortEnd={onSortUpdate} />
  );
}

export default SimilarShowsList;