import { useState } from "react";
import authService from "../services/authService";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateForm = (email) => {
    setEmail(email)
    setError(!validateEmail(email))
  }

  const resetPassword = async () => {
    if(!error){
      // login with google
      let [success, err] = await authService.resetPassword(email)
      if(success){
        toast.success("Password reset email sent.")
        // navigate to Login
        navigate( "/login", { replace: true } );
      } else {

        let message = "Password reset failed."
        if(err.code === "auth/invalid-email"){
          message = "Invalid email address."
        } else if ( err.code === "auth/user-not-found" ){
          message = "User not found."
        }

        toast.error(message)
      }
    }
  }

  return ( 
    <div className="hero full">
    <div className="wrap">
      <div className="tac">
          <div className="bx p0 w460 br mx-auto mt mb">
            <div className="hdr act flex">
              <div className="p1">Not registered yet?</div>
              <div className="tar ps"><Link className="btn" to="/register">Register</Link></div>
            </div>
        
            <div className="p1">
              <h3>Forgot Password</h3>
              <p>Enter your email to reset your password.</p>

              <div className="">
              <div className="field mbs"><input type="email" value={email} onChange={(e) => validateForm(e.target.value)} />{ error && <div className="error">Must be a valid email</div>}</div>
              <div className="field mbs tac"><button onClick={resetPassword}>Reset password</button></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  );
}

export default ForgotPassword;