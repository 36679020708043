import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import showsService from "../../services/showsService";
import PageHeader from "../../components/dashboard/PageHeader";
import ShowEditor from "../../components/show/ShowEditor";

const EditShow = () => {

  const params = useParams();
  const [show, setShow] = useState();

  useEffect(() => {
    async function loadShowInformation() {
      console.log("loading show information");
      const show = await showsService.getShow(params.id);
      setShow(show);
    }

    loadShowInformation();
  }, [params.id]);

  return (
    <div className="wrap">
      <div>
        <PageHeader title="Edit Show" />
        {show && 
          <ShowEditor 
            show={show} 
            isNew={false} 
            onDelete={() => {}} />
        }
      </div>
    </div>
  );
};

export default EditShow;
