import { Link } from "react-router-dom";
import RegisterForm from "../components/RegisterForm";
import authService from "../services/authService";


const Register = () => {

  const submitRegisterForm = async ( registerFormData ) => {
    // submit form
    let result = await authService.register( registerFormData )
    if( result?.data?.uid ){
      // login new registered user
      authService.login( registerFormData )
    }
  }

  return ( 
    <div className="hero full">
      <div className="wrap tac">
        <div className="bx p0 br w460 mx-auto mt mb">
          <div className="p1">
            <p className="lead">Register to use WPCrawl for <strong>free</strong></p>
            <RegisterForm
              onSubmit={submitRegisterForm} />
          </div>
        </div>
        <p>Already have an account? <Link to={'/login'}>Log in</Link></p>
      </div>
    </div>

  );
}

export default Register;