const InputField = ({name, label, description, error, type, ...rest}) => {
  return ( 
    <div className="field mbs">
    {type !== "hidden" && <label htmlFor={name}>{label}</label>}
      <input
        className={ error && 'has-error'}
        {...rest}
        name={name}
        id={name}
        type={type}
      />
    { error && <div className="error">{error}</div>}
    { (!error && description) && <div className="description">{description}</div>}
    </div>
  );
}

export default InputField;