
function init(){

}

function log(...args){
  if(process.env.NODE_ENV !== 'production'){
    console.log(...args)
  }
}

const logService = {
  init,
  log
}

export default logService;