
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from '../App'
import { useEffect } from 'react'
import ShowsGrid from '../components/show/ShowsGrid';
import showsService from '../services/showsService';
import { DebounceInput } from 'react-debounce-input';

export default function Dashboard() {

  const { user, appUser } = useContext(UserContext);
  const userLoaded = appUser && user;

  const updateFilters = (e) => {
    // reset to first page
    setPagination({ ...pagination, page: 1 })
    // update search text
    setSearchText(e.target.value)
  }

  const [searchText, setSearchText] = useState('')
  const [pagination, setPagination] = useState({ page: 1, limit: 10 })
  const [totalPages, setTotalPages] = useState(0)
  const [shows, setShows] = useState([])
  const [showsCount, setShowsCount] = useState(0)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [hasPrevPage, setHasPrevPage] = useState(false)


  useEffect(() => {
    if ( showsCount > (pagination.page + 1) * pagination.limit ) {
      setHasNextPage(true)
    }
    else {
      setHasNextPage(false)
    }

    if ( pagination.page > 0 ) {
      setHasPrevPage(true)
    }
    else {
      setHasPrevPage(false)
    }

    let totalPagesValue = Math.ceil(showsCount / pagination.limit)
    setTotalPages(totalPagesValue)

  }
  , [showsCount, pagination])


  useEffect(() => {
    const fetchShows = async () => {
      // search="", page, limit, sort
      const res = await showsService.getShows( { search: searchText, page: pagination.page, limit: pagination.limit, sort: 'updated:asc', _fields: 'id,name,network,year,genres,genresInputs,themes,themesInputs,terms,updated'} )
      if (!res) return
      setShows(res.data)
      setShowsCount(res.count)
    }

    fetchShows()
  }, [user, pagination, searchText])

  return (
    <div className="wrap flex">
    <div className="main">
    { userLoaded &&
      <div>
        <div className="welcome">
          <h2>Welcome {user.displayName}!</h2>
        </div>

        <div className="tlbx mbs">
          <div className='flex aic nw'>
            <div className='flex'>
              <DebounceInput type="text" placeholder="Search" debounceTimeout={400} className='search' value={searchText} onChange={updateFilters} />
              {searchText && <button onClick={() => setSearchText('')} className='btn btn-sm'>x</button>}
            </div>
              <button className='btn btn-sm' disabled={!hasPrevPage} onClick={() => setPagination({ ...pagination, page: Math.max(0, pagination.page - 1) })}>&lt;</button>
              <span className='current-page'>{pagination.page} / {totalPages}</span>
              <button className='btn btn-sm' disabled={!hasNextPage} onClick={() => setPagination({ ...pagination, page: Math.min(100, pagination.page + 1) })}>&gt;</button>
            <Link to="/admin/shows/new" className="btn btn-sm">Add New</Link>
          </div>
        </div>

        <ShowsGrid shows={shows} />

      </div>
    }
    </div>
    </div>
  )
}
