
import { useState } from 'react';
import { useEffect } from 'react';
import showsService from '../services/showsService';
import { Link } from 'react-router-dom';

const Shows = () => {

  const [shows, setShows] = useState([]);

  useEffect(() =>  { 
    const loadShows = async () => {
      // load shows using shows service
      console.log("loading shows")
      let res = await showsService.getShows();
      if(!res) return;
      const shows = res.data;
      // setShows(shows);
      console.log("shows", shows)
      setShows(shows);
    }
    loadShows();

  }, []);

  return (
    <div className='container'>
      <h1>Shows</h1>
      <div className="row">
      {shows && shows.map((show) => (
        <div className="col-md-6 mb1" key={show.id}>
          <div className="card">
          <img src={show.image} className="card-img-top" alt={show.name} />

          <div className="card-body">
            <h5 className="card-title">{show.name}</h5>
            <p className="card-text">{ show.summary }</p>
            
          </div>
          <div className="card-footer">
            <Link to={`/shows/${show.id}`} className="btn btn-primary">View</Link>
            <Link to={`/admin/shows/${show.id}/edit`} className="btn btn-secondary">Edit</Link>
          </div>
          </div>
        </div>
      ))}
      {!shows && <p>Loading shows...</p>}
      </div>
    </div>
  );
};

export default Shows;