import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../App';

const NavMenu = () => {

  const { user } = useContext(UserContext);
  const [menuState, setMenuState] = React.useState(false);
  let location = useLocation();

  const toggleMenu = (e) => {
    setMenuState(!menuState);
  }


  React.useEffect(() => {
    // close the menu when the location changes
    setMenuState(false);
  }, [location]);


  return ( 
    <React.Fragment>
    <input type="checkbox" id="dw-cb" checked={menuState} onChange={toggleMenu} />
    <div className="dw" id="dw">
      <div className="dw-hdr">
        <Link to="/" className="logo">ShowsLike</Link>
        <label className="x" htmlFor="dw-cb" tabIndex="1">&times;</label>
      </div>
      <nav className="dw-nav">
        <ul>
          <li><Link to="/admin">Home</Link></li>
          
          { user && <>
            <li><Link to="/admin/shows/new">New Show</Link></li>
            <li><Link to="/logout">Logout</Link></li>
          </>}

        </ul>
      </nav>
      <div className="p1 tac s">
        <small>&copy; 2022. All rights reserved.</small>
      </div>
    </div>
    <div className="o"><label className="o-x" htmlFor="dw-cb"></label></div>
    </React.Fragment>
  );
}

export default NavMenu;