import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import showsService from "../services/showsService";
import ShowHeader from "../components/show/ShowHeader";

const Show = () => {

  const params = useParams();
  const [show, setShow] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadSiteInformation(){
      setLoading(true);
      const show = await showsService.getShow( params.id )
      setShow( show );
      setLoading(false);
    }

    loadSiteInformation(params.id)
  }, [params.id])

  return ( 
    <div className="container">
      <div className="row">
        <div className="col-md-8">
        {show &&
          <div className="show">
            <ShowHeader show={show} />
            <p className="">{show.summary}</p>
          </div>
        }
        {loading && <p>Loading show...</p>}
        </div>
        <div className="col-md-4">
          <h2>Details</h2>
          <p>Details go here</p>
        </div>
      </div>
    </div>
  );
}

export default Show;