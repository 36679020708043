import { slugify } from "./slugify";


export const networksList = [
  { "wpid": 21, "name": "A&E", "slug": "a-and-e", "type": "cable", "redirect": "" },
  { "wpid": 1, "name": "ABC", "slug": "abc", "type": "broadcast", "redirect": "" },
  { "wpid": 189, "name": "Acorn TV", "slug": "acorn-tv", "type": "streaming", "redirect": "" },
  { "wpid": 22, "name": "Adult Swim", "slug": "adult-swim", "type": "cable", "redirect": "" },
  { "wpid": 216, "name": "ALLBLK", "slug": "allblk", "type": "streaming", "redirect": "" },
  { "wpid": 32, "name": "Amazon", "slug": "amazon", "type": "", "redirect": "" },
  { "wpid": 6, "name": "AMC", "slug": "amc", "type": "cable", "redirect": "" },
  { "wpid": 218, "name": "AMC+", "slug": "amc-plus", "type": "streaming", "redirect": "" },
  { "wpid": 28, "name": "American Heroes Channel", "slug": "american-heroes-channel", "type": "", "redirect": "" },
  { "wpid": 30, "name": "Animal Planet", "slug": "animal-planet", "type": "cable", "redirect": "" },
  { "wpid": 179, "name": "Apple Music", "slug": "apple-music", "type": "", "redirect": "" },
  { "wpid": 201, "name": "Apple TV+", "slug": "apple-tv-plus", "type": "streaming", "redirect": "" },
  { "wpid": 89, "name": "Arena", "slug": "arena", "type": "", "redirect": "" },
  { "wpid": 72, "name": "Audience Network", "slug": "audience-network", "type": "", "redirect": "" },
  { "wpid": 182, "name": "AXN", "slug": "axn", "type": "", "redirect": "" },
  { "wpid": 113, "name": "AXS TV", "slug": "axs-tv", "type": "", "redirect": "" },
  { "wpid": 8, "name": "BBC America", "slug": "bbc-america", "type": "", "redirect": "" },
  { "wpid": 177, "name": "BBC Four", "slug": "bbc-four", "type": "", "redirect": "" },
  { "wpid": 7, "name": "BBC One", "slug": "bbc-one", "type": "", "redirect": "" },
  { "wpid": 62, "name": "BBC Three", "slug": "bbc-three", "type": "", "redirect": "" },
  { "wpid": 69, "name": "BBC Two", "slug": "bbc-two", "type": "", "redirect": "" },
  { "wpid": 117, "name": "BET", "slug": "bet", "type": "cable", "redirect": "" },
  { "wpid": 213, "name": "BET+", "slug": "bet-plus", "type": "streaming", "redirect": "" },
  { "wpid": 156, "name": "Boomerang", "slug": "boomerang", "type": "", "redirect": "" },
  { "wpid": 92, "name": "Bounce TV", "slug": "bounce-tv", "type": "", "redirect": "" },
  { "wpid": 42, "name": "Bravo", "slug": "bravo", "type": "cable", "redirect": "" },
  { "wpid": 135, "name": "BYUtv", "slug": "byutv", "type": "", "redirect": "" },
  { "wpid": 147, "name": "Canal+", "slug": "canal-plus", "type": "", "redirect": "" },
  { "wpid": 74, "name": "Cartoon Network", "slug": "cartoon-network", "type": "", "redirect": "" },
  { "wpid": 45, "name": "CBBC", "slug": "cbbc", "type": "", "redirect": "" },
  { "wpid": 104, "name": "CBC", "slug": "cbc", "type": "", "redirect": "" },
  { "wpid": 2, "name": "CBS", "slug": "cbs", "type": "broadcast", "redirect": "" },
  { "wpid": 122, "name": "CBS All Access", "slug": "cbs-all-access", "type": "", "redirect": "" },
  { "wpid": 19, "name": "Channel 4", "slug": "channel-4", "type": "", "redirect": "" },
  { "wpid": 101, "name": "Channel 5", "slug": "channel-5", "type": "", "redirect": "" },
  { "wpid": 118, "name": "Chiller", "slug": "chiller", "type": "", "redirect": "" },
  { "wpid": 9, "name": "Cinemax", "slug": "cinemax", "type": "", "redirect": "" },
  { "wpid": 37, "name": "Citytv", "slug": "citytv", "type": "", "redirect": "" },
  { "wpid": 73, "name": "CMT", "slug": "cmt", "type": "cable", "redirect": "" },
  { "wpid": 70, "name": "CNBC", "slug": "cnbc", "type": "cable", "redirect": "" },
  { "wpid": 49, "name": "CNN", "slug": "cnn", "type": "cable", "redirect": "" },
  { "wpid": 39, "name": "Comedy Central", "slug": "comedy-central", "type": "cable", "redirect": "" },
  { "wpid": 131, "name": "Comedy Network", "slug": "comedy-network", "type": "", "redirect": "" },
  { "wpid": 169, "name": "Comic-Con HQ", "slug": "comic-con-hq", "type": "", "redirect": "" },
  { "wpid": 75, "name": "Cooking Channel", "slug": "cooking-channel", "type": "cable", "redirect": "" },
  { "wpid": 207, "name": "CourtTV", "slug": "court-tv", "type": "", "redirect": "" },
  { "wpid": 64, "name": "Crackle", "slug": "crackle", "type": "streaming", "redirect": "" },
  { "wpid": 132, "name": "CTV", "slug": "ctv", "type": "", "redirect": "" },
  { "wpid": 106, "name": "CW Seed", "slug": "cw-seed", "type": "", "redirect": "" },
  { "wpid": 126, "name": "Dave", "slug": "dave", "type": "", "redirect": "" },
  { "wpid": 191, "name": "DC Universe", "slug": "dc-universe", "type": "", "redirect": "" },
  { "wpid": 52, "name": "Destination America", "slug": "destination-america", "type": "cable", "redirect": "" },
  { "wpid": 29, "name": "Discovery", "slug": "discovery", "type": "cable", "redirect": "" },
  { "wpid": 112, "name": "Discovery Canada", "slug": "discovery-canada", "type": "", "redirect": "" },
  { "wpid": 38, "name": "Discovery Channel", "slug": "discovery-channel", "type": "cable", "redirect": "" },
  { "wpid": 152, "name": "Discovery en Español", "slug": "discovery-en-español", "type": "", "redirect": "" },
  { "wpid": 65, "name": "Discovery Family", "slug": "discovery-family", "type": "cable", "redirect": "" },
  { "wpid": 55, "name": "Discovery HnH", "slug": "discovery-hnh", "type": "", "redirect": "" },
  { "wpid": 90, "name": "Discovery Life", "slug": "discovery-life", "type": "", "redirect": "" },
  { "wpid": 214, "name": "Discovery+", "slug": "discovery-plus", "type": "streaming", "redirect": "" },
  { "wpid": 38, "name": "Disney", "slug": "disney", "type": "", "redirect": "" },
  { "wpid": 84, "name": "Disney Junior", "slug": "disney-junior", "type": "cable", "redirect": "" },
  { "wpid": 12, "name": "Disney XD", "slug": "disney-xd", "type": "cable", "redirect": "" },
  { "wpid": 200, "name": "Disney+", "slug": "disney-plus", "type": "streaming", "redirect": "" },
  { "wpid": 66, "name": "DIY Network", "slug": "diy-network", "type": "", "redirect": "" },
  { "wpid": 63, "name": "E!", "slug": "e", "type": "cable", "redirect": "" },
  { "wpid": 137, "name": "E! News", "slug": "e-news", "type": "cable", "redirect": "" },
  { "wpid": 94, "name": "E4", "slug": "e4", "type": "", "redirect": "" },
  { "wpid": 102, "name": "El Rey Network", "slug": "el-rey-network", "type": "", "redirect": "" },
  { "wpid": 136, "name": "Eleven", "slug": "eleven", "type": "", "redirect": "" },
  { "wpid": 128, "name": "EPIX", "slug": "epix", "type": "", "redirect": "/network/mgm-plus" },
  { "wpid": 192, "name": "ESPN", "slug": "espn", "type": "cable", "redirect": "" },
  { "wpid": 87, "name": "Esquire Network", "slug": "esquire-network", "type": "", "redirect": "" },
  { "wpid": 178, "name": "Facebook", "slug": "facebook", "type": "", "redirect": "" },
  { "wpid": 79, "name": "Food Network", "slug": "food-network", "type": "cable", "redirect": "" },
  { "wpid": 4, "name": "FOX", "slug": "fox", "type": "broadcast", "redirect": "" },
  { "wpid": 130, "name": "FOX Sports 1", "slug": "fox-sports-1", "type": "", "redirect": "" },
  { "wpid": 20, "name": "Freeform", "slug": "freeform", "type": "cable", "redirect": "" },
  { "wpid": 210, "name": "freevee", "slug": "freevee", "type": "streaming", "redirect": "" },
  { "wpid": 173, "name": "Fuji TV", "slug": "fuji-tv", "type": "", "redirect": "" },
  { "wpid": 110, "name": "Fullscreen", "slug": "fullscreen", "type": "", "redirect": "" },
  { "wpid": 139, "name": "Fuse", "slug": "fuse", "type": "", "redirect": "" },
  { "wpid": 123, "name": "Fusion", "slug": "fusion", "type": "", "redirect": "" },
  { "wpid": 46, "name": "FX", "slug": "fx", "type": "cable", "redirect": "" },
  { "wpid": 159, "name": "FXX", "slug": "fxx", "type": "cable", "redirect": "" },
  { "wpid": 76, "name": "FYI", "slug": "fyi", "type": "", "redirect": "" },
  { "wpid": 88, "name": "GAC", "slug": "gac", "type": "cable", "redirect": "" },
  { "wpid": 47, "name": "Global", "slug": "global", "type": "", "redirect": "" },
  { "wpid": 98, "name": "go90", "slug": "go90", "type": "", "redirect": "" },
  { "wpid": 71, "name": "GSN", "slug": "gsn", "type": "", "redirect": "" },
  { "wpid": 60, "name": "Hallmark Channel", "slug": "hallmark-channel", "type": "cable", "redirect": "" },
  { "wpid": 14, "name": "HBO", "slug": "hbo", "type": "cable", "redirect": "" },
  { "wpid": 165, "name": "HBO Canada", "slug": "hbo-canada", "type": "", "redirect": "" },
  { "wpid": 204, "name": "HBO Max", "slug": "hbo-max", "type": "streaming", "redirect": "/network/max" },
  { "wpid": 58, "name": "HGTV", "slug": "hgtv", "type": "cable", "redirect": "" },
  { "wpid": 160, "name": "HGTV Canada", "slug": "hgtv-canada", "type": "cable", "redirect": "" },
  { "wpid": 44, "name": "History", "slug": "history", "type": "cable", "redirect": "" },
  { "wpid": 148, "name": "HLN", "slug": "hln", "type": "cable", "redirect": "" },
  { "wpid": 26, "name": "Hulu", "slug": "hulu", "type": "streaming", "redirect": "" },
  { "wpid": 43, "name": "Investigation Discovery", "slug": "investigation-discovery", "type": "cable", "redirect": "" },
  { "wpid": 43, "name": "ID", "slug": "id", "type": "cable", "redirect": "investigation-discovery" },
  { "wpid": 97, "name": "IFC", "slug": "ifc", "type": "", "redirect": "" },
  { "wpid": 210, "name": "IMDb TV", "slug": "imdb-tv", "type": "streaming", "redirect": "freevee" },
  { "wpid": 16, "name": "ITV", "slug": "itv", "type": "", "redirect": "" },
  { "wpid": 157, "name": "ITV Encore", "slug": "itv-encore", "type": "", "redirect": "" },
  { "wpid": 96, "name": "ITV2", "slug": "itv2", "type": "", "redirect": "" },
  { "wpid": 142, "name": "ITVBe", "slug": "itvbe", "type": "", "redirect": "" },
  { "wpid": 176, "name": "Kanal 5", "slug": "kanal-5", "type": "", "redirect": "" },
  { "wpid": 59, "name": "Lifetime", "slug": "lifetime", "type": "cable", "redirect": "" },
  { "wpid": 67, "name": "LMN", "slug": "lmn", "type": "", "redirect": "" },
  { "wpid": 50, "name": "Logo", "slug": "logo", "type": "", "redirect": "" },
  { "wpid": 66, "name": "Magnolia", "slug": "magnolia", "type": "cable", "redirect": "" },
  { "wpid": 204, "name": "Max", "slug": "max", "type": "streaming", "redirect": "" },
  { "wpid": 172, "name": "MBS", "slug": "mbs", "type": "", "redirect": "" },
  { "wpid": 128, "name": "MGM+", "slug": "mgm-plus", "type": "streaming", "redirect": "" },
  { "wpid": 103, "name": "More4", "slug": "more4", "type": "", "redirect": "" },
  { "wpid": 193, "name": "MotorTrend", "slug": "motortrend", "type": "cable", "redirect": "" },
  { "wpid": 193, "name": "MotorTrend Network", "slug": "motortrend-network", "type": "cable", "redirect": "motortrend" },
  { "wpid": 124, "name": "MSNBC", "slug": "msnbc", "type": "", "redirect": "" },
  { "wpid": 11, "name": "MTV", "slug": "mtv", "type": "cable", "redirect": "" },
  { "wpid": 164, "name": "MTV Live", "slug": "mtv-live", "type": "", "redirect": "" },
  { "wpid": 56, "name": "MTV2", "slug": "mtv2", "type": "", "redirect": "" },
  { "wpid": 40, "name": "National Geographic", "slug": "national-geographic", "type": "cable", "redirect": "" },
  { "wpid": 40, "name": "Nat Geo", "slug": "nat-geo", "type": "cable", "redirect": "nat-geo" },
  { "wpid": 107, "name": "Nat Geo WILD", "slug": "nat-geo-wild", "type": "cable", "redirect": "" },
  { "wpid": 5, "name": "NBC", "slug": "nbc", "type": "broadcast", "redirect": "" },
  { "wpid": 17, "name": "Netflix", "slug": "netflix", "type": "streaming", "redirect": "" },
  { "wpid": 158, "name": "NFL Network", "slug": "nfl-network", "type": "", "redirect": "" },
  { "wpid": 13, "name": "Nickelodeon", "slug": "nickelodeon", "type": "cable", "redirect": "" },
  { "wpid": 125, "name": "Nicktoons", "slug": "nicktoons", "type": "", "redirect": "" },
  { "wpid": 143, "name": "Omni", "slug": "omni", "type": "", "redirect": "" },
  { "wpid": 51, "name": "OWN", "slug": "own", "type": "cable", "redirect": "" },
  { "wpid": 68, "name": "Oxygen", "slug": "oxygen", "type": "cable", "redirect": "" },
  { "wpid": 183, "name": "Paramount Network", "slug": "paramount-network", "type": "", "redirect": "" },
  { "wpid": 215, "name": "Paramount+", "slug": "paramount-plus", "type": "streaming", "redirect": "" },
  { "wpid": 81, "name": "PBS", "slug": "pbs", "type": "broadcast", "redirect": "" },
  { "wpid": 82, "name": "PBS Kids", "slug": "pbs-kids", "type": "", "redirect": "" },
  { "wpid": 203, "name": "Peacock TV", "slug": "peacock-tv", "type": "streaming", "redirect": "" },
  { "wpid": 115, "name": "Pivot", "slug": "pivot", "type": "", "redirect": "" },
  { "wpid": 109, "name": "Playstation Network", "slug": "playstation-network", "type": "", "redirect": "" },
  { "wpid": 91, "name": "Pop TV", "slug": "pop-tv", "type": "", "redirect": "" },
  { "wpid": 205, "name": "Prime Video", "slug": "prime-video", "type": "streaming", "redirect": "" },
  { "wpid": 133, "name": "Really", "slug": "really", "type": "", "redirect": "" },
  { "wpid": 78, "name": "Reelz", "slug": "reelz", "type": "cable", "redirect": "" },
  { "wpid": 217, "name": "Roku", "slug": "roku", "type": "streaming", "redirect": "" },
  { "wpid": 53, "name": "Science Channel", "slug": "science-channel", "type": "cable", "redirect": "" },
  { "wpid": 77, "name": "Seeso", "slug": "seeso", "type": "", "redirect": "" },
  { "wpid": 187, "name": "Seven Network", "slug": "seven-network", "type": "", "redirect": "" },
  { "wpid": 57, "name": "Showtime", "slug": "showtime", "type": "cable", "redirect": "" },
  { "wpid": 212, "name": "Shudder", "slug": "shudder", "type": "streaming", "redirect": "" },
  { "wpid": 120, "name": "Sky 1", "slug": "sky-1", "type": "", "redirect": "" },
  { "wpid": 174, "name": "Sky Arts", "slug": "sky-arts", "type": "", "redirect": "" },
  { "wpid": 25, "name": "Sky Atlantic", "slug": "sky-atlantic", "type": "", "redirect": "" },
  { "wpid": 23, "name": "Sky Italia", "slug": "sky-italia", "type": "", "redirect": "" },
  { "wpid": 140, "name": "Sky Living", "slug": "sky-living", "type": "", "redirect": "" },
  { "wpid": 18, "name": "Sky One", "slug": "sky-one", "type": "", "redirect": "" },
  { "wpid": 83, "name": "Smithsonian Channel", "slug": "smithsonian-channel", "type": "cable", "redirect": "" },
  { "wpid": 202, "name": "Spectrum", "slug": "spectrum", "type": "", "redirect": "" },
  { "wpid": 95, "name": "Sprout", "slug": "sprout", "type": "", "redirect": "" },
  { "wpid": 129, "name": "Stan", "slug": "stan", "type": "", "redirect": "" },
  { "wpid": 48, "name": "Starz", "slug": "starz", "type": "cable", "redirect": "" },
  { "wpid": 190, "name": "Sundance Now", "slug": "sundance-now", "type": "", "redirect": "" },
  { "wpid": 24, "name": "Sundance TV", "slug": "sundance-tv", "type": "cable", "redirect": "" },
  { "wpid": 15, "name": "Syfy", "slug": "syfy", "type": "cable", "redirect": "" },
  { "wpid": 121, "name": "Syndication", "slug": "syndication", "type": "", "redirect": "" },
  { "wpid": 219, "name": "T+E", "slug": "t-plus-e", "type": "", "redirect": "" },
  { "wpid": 80, "name": "TBS", "slug": "tbs", "type": "cable", "redirect": "" },
  { "wpid": 168, "name": "Telemundo", "slug": "telemundo", "type": "", "redirect": "" },
  { "wpid": 161, "name": "Teletoon", "slug": "teletoon", "type": "", "redirect": "" },
  { "wpid": 3, "name": "The CW", "slug": "the-cw", "type": "broadcast", "redirect": "" },
  { "wpid": 54, "name": "TLC", "slug": "tlc", "type": "cable", "redirect": "" },
  { "wpid": 33, "name": "TNT", "slug": "tnt", "type": "cable", "redirect": "" },
  { "wpid": 34, "name": "Travel Channel", "slug": "travel-channel", "type": "cable", "redirect": "" },
  { "wpid": 35, "name": "truTV", "slug": "trutv", "type": "", "redirect": "" },
  { "wpid": 0, "name": "TV", "slug": "tv", "type": "broadcast", "redirect": "" },
  { "wpid": 61, "name": "TV Land", "slug": "tv-land", "type": "", "redirect": "" },
  { "wpid": 105, "name": "TV One", "slug": "tv-one", "type": "cable", "redirect": "" },
  { "wpid": 153, "name": "TV3", "slug": "tv3", "type": "", "redirect": "" },
  { "wpid": 170, "name": "TV4", "slug": "tv4", "type": "", "redirect": "" },
  { "wpid": 198, "name": "TVNZ", "slug": "tvnz", "type": "", "redirect": "" },
  { "wpid": 211, "name": "UMC", "slug": "umc", "type": "", "redirect": "" },
  { "wpid": 116, "name": "UP", "slug": "up", "type": "cable", "redirect": "" },
  { "wpid": 31, "name": "USA Network", "slug": "usa-network", "type": "broadcast", "redirect": "" },
  { "wpid": 108, "name": "Velocity", "slug": "velocity", "type": "", "redirect": "" },
  { "wpid": 41, "name": "VH1", "slug": "vh1", "type": "cable", "redirect": "" },
  { "wpid": 99, "name": "Viceland", "slug": "viceland", "type": "", "redirect": "" },
  { "wpid": 111, "name": "W Network", "slug": "w-network", "type": "", "redirect": "" },
  { "wpid": 162, "name": "Warner Bros", "slug": "warner-bros", "type": "", "redirect": "" },
  { "wpid": 36, "name": "We tv", "slug": "we-tv", "type": "cable", "redirect": "" },
  { "wpid": 27, "name": "WGN America", "slug": "wgn-america", "type": "", "redirect": "" },
  { "wpid": 86, "name": "Youtube Premium", "slug": "youtube-premium", "type": "streaming", "redirect": "" }
]


export const filterNetworks = (search) => {
  return networksList.find(n => n.name.toLowerCase().includes(search.toLowerCase()));
}


export const getNetworkNameByID = (networkID) => {
  return networksList.find(n => n.wpid === networkID).name;
}


export const getNetworkNameBySlug = (networkSlug) => {
  return networksList.find(n => n.slug === networkSlug).name;
}


export const sanitizeNetworkName = (networkName) => {
  const networkSlug = slugifyNetwork(networkName);
  return getNetworkNameBySlug(networkSlug);
}


export const slugifyNetwork = (network) => {
  return slugify(
    network
      .replace(/&/g, ' and ')
      .replace(/\+$/g, ' plus')
      .replace(/\+/g, ' plus ')
  );
}


const networks = {
  slugifyNetwork,
  filterNetworks,
  getNetworkNameByID,
  getNetworkNameBySlug,
  sanitizeNetworkName,
  networksList
};

export default networks;
