import siteSettings from "../config/site-settings"
import httpService from "./httpService"
import logger from "./logService"

const apiEndpoint = siteSettings.apiUrl + "/shows"

export const getShow = async (id) => {
  let result = await httpService.get( apiEndpoint + `/${id}` );
  if(!result) return null;
  return result.data
}


export const getShows = async ( params ) => {
  console.log("getShows", { params })
  let result = await httpService.get( apiEndpoint, { params });
  if(!result) return null;
  return result.data
}


export const getThemes = async ( params ) => {
  let result = await httpService.get( apiEndpoint + `/themes`, { params } );
  if(!result) return null;
  return result.data
}


export const getShowBySlug = async ( slug ) => {
  let result = await httpService.get( apiEndpoint + `/byslug`, { params: { slug } } );
  if(!result) return null;
  return result.data
}


export const getRecentShows = async (limit) => {
  let result = await httpService.get( apiEndpoint + "/recent", { params: { limit } } );
  if(!result) return null;
  return result.data
}


export const createOrUpdate = async (show) => {
  if(show.id){
    return updateShow(show)
  } else {
    return createShow(show)
  }
}


export const updateShow = async ( show ) => {
  let result = await httpService.put( apiEndpoint + `/${show.id}`, show );
  if(!result) return null;
  return result.data;
}


const patchShow = async ( id, payload ) => {
  let result = await httpService.patch( apiEndpoint + `/${id}`, payload );
  if(!result) return null;
  return result.data;
}


export const createShow = async ( show ) => {
  let result = await httpService.post( apiEndpoint, show  );
  if(!result) return null;
  return result.data;
}


export const deleteShow = async ( id ) => {
  try{
    let result = await httpService.delete( apiEndpoint + `/${id}` );
    if(!result) return false;
    return true;
  } catch (err) {
    logger.log(err);
  }
}


const showsService = {
  getShow,
  getShows,
  createShow,
  updateShow,
  createOrUpdate,
  deleteShow,
  patchShow,
  getRecentShows,
  getShowBySlug
};

export default showsService;
