
import Form from "./common/form";
const Joi = require('joi-browser');

export default class LoginForm extends Form {

  state = {
    data: { email: "", password: "" },
    errors: {}
  };

  schema = {
    email: Joi.string()
      .email()
      .min(6)
      .max(65)
      .required()
      .label('Email'),
    password: Joi.string()
      .min(6)
      .max(24)
      .required()
      .label('Password')
  }

  doSubmit = async () => {
    this.props.onSubmit( this.state.data );
  }

  render() {

    return (
      <form className="form" onSubmit={this.handleSubmit}>
        { this.renderInput("email", "Email") }
        { this.renderInput("password", "Password", "password") }
        <div className="tac">
          { this.renderButton("Login") }
        </div>
      </form>
    )
  }
}




