import PageHeader from "../../components/dashboard/PageHeader";
import ShowEditor from "../../components/show/ShowEditor";

export default function NewShow() {
  return (
    <div className="wrap flex">
      <div className="main">
        <div>
          <PageHeader title="New Show" />
          <ShowEditor
            isNew={true}
            show={{
              name: "",
              summary: "",
              genresInput: "",
              themesInput: "",
              year: 2024,
            }}
            onSubmit={() => {}}
          />
        </div>
      </div>
    </div>
  );
}
