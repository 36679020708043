const Select = ({name, label, error, options, fields, ...rest}) => {
  return ( 
    <div className="field">
    <label htmlFor={name}>{label}</label>
      <select
        className={ error && 'has-error'}
        {...rest}
        name={name}
        id={name} >
        {options.map(option => (
          <option 
            key={option[fields["key"]]} 
            value={option[fields["key"]]}>
              {option[fields["value"]]}
            </option>
        ))}
      </select>
    { error && <div className="error">{error}</div>}</div>
  );
}

export default Select;