const Checkbox = ({name, label, isChecked, description, error, ...rest}) => {

  return (
  <div className="field checkbox mbs">
    <label htmlFor={name}>
    <input type="checkbox"
      className={ error && 'has-error'}
      {...rest}
      name={name}
      id={name}
      checked={isChecked}
    /> {label}</label>
  { error && <div className="error">{error}</div>}
  { (!error && description) && <div className="description">{description}</div>}
  </div>
  );
}

Checkbox.defaultProps = {
  isChecked: false
};

export default Checkbox;