import { createRef, useEffect, useState } from "react";
import { Col, Row, } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import { toast } from "react-toastify";
import Moment from "react-moment";

import showsService from "../../services/showsService";
import siteSettings from "../../config/site-settings";
import TermsInput from "../common/TermsInput";
import { useNavigate } from "react-router-dom";
import SimilarShowsList from "../SimilarShowsList";
import { arrayMoveImmutable } from "array-move";
import ShowNameInput from "../ShowNameInput";
import ShowTaxInput from "../ShowTaxInput";
import dataService from "../../services/dataService";
import { slugifyNetwork, getNetworkNameBySlug } from "../../util/networks";


const termsToInput = ( terms, taxonomy, arr, inp ) => {
  /*{
    "_id": "665602c1321bd24e76bcdb05",
    "slug": "drama",
    "name": "Drama",
    "taxonomy": "genre",
    "description": "",
    "meta": [],
    "isActive": true,
    "isDeleted": false,
    "created": "2024-05-28T16:13:53.984Z",
    "updated": "2024-05-28T16:13:53.984Z",
    "__v": 0,
    "id": "665602c1321bd24e76bcdb05"
  }*/
  if(!terms || terms.length === 0) {
    if(!arr && !inp) return '';
    if(arr) return arr.join(',');
    if(inp) return inp;
    return '';
  };

  return terms.filter(t => t.taxonomy === taxonomy).map(t => t.name).join(',');

}


const ShowEditor = ( { show, isNew } ) => {


  console.log( 'ShowEditor', show )

  const [patch, setPatch] = useState({})
  const [loading, setLoading] = useState(false)
  const [similarsList, setSimilarsList] = useState([])
  const newSimilarsRef = createRef();
  const newSimilarsTaxRef = createRef();
  const navigate = useNavigate();

  const [genresInput, setGenresInput] = useState( termsToInput( show.terms, 'genre', show.genres, show.genresInput ))
  const [themesInput, setThemesInput] = useState( termsToInput( show.terms, 'theme', show.themes, show.themesInput ))
  const [sectionsInput, setSectionsInput] = useState( termsToInput( show.terms, 'section', [], '' ))

  useEffect(() => {
    if(show.similar){
      show.similarShows = show.similar.split("|").map(s => s.trim());
      setSimilarsList( show.similarShows )
    }

    // todo: fix network name
    show.network = getNetworkNameBySlug( slugifyNetwork(show.network) )

    setGenresInput( termsToInput( show.terms, 'genre', show.genres, show.genresInput ))
    setThemesInput( termsToInput( show.terms, 'theme', show.themes, show.themesInput ))
    setSectionsInput( termsToInput( show.terms, 'section', [], '' ))

  }, [show])

  const sanitizeShowName = (showname) => {
    return showname.replace(/`/,"'").replace(/[^-A-Za-z0-9:!,+?&#'\\. ]/gi,'')
  }

  const setTerms = (terms, key) => {
    console.log( 'setTerms triggered..' )
    console.log( terms )
    console.log( key )

    terms = terms.map(t => (typeof t === 'object' && !Array.isArray(t) && t !== null) ? t["name"] : t)
    console.log( terms )

    patchField({key, value: terms.join(',')})
  }

  const patchField = ({key, value}) => {
    console.log( 'patch triggered..' )
    console.log( key, ':', value )

    if( key === 'summary' ){
      value = value.trim()
    }

    setPatch({...patch, [key]: value })
  }

  const onClickUpdate = async () => {
    if( !patch || Object.keys(patch).length === 0 ){
      toast.warn("No changes detected.")
      return;
    }

    if(loading) return;

    console.log('updating post..')
    console.log( patch )
    setLoading(true)
    const { status, message, data } = await showsService.patchShow( show.id, patch );

    if( 'success' === status && data && data.id ){
      toast.success( message )
    } else {
      toast.warn( message )
    }
    setLoading(false)
  }

  const onClickSave = async () => {
    console.log('saving post..')
    console.log( patch )
    const res = await showsService.createShow( patch );
    console.log( res )
/*
    if( 'success' === status && data && data.ID ){
      toast.success("post saved.")
    }*/
  }


  const updateSimilars = ({oldIndex, newIndex}) => {
    if( oldIndex === newIndex ) return;
    let updatedList = arrayMoveImmutable(similarsList, oldIndex, newIndex);
    setSimilarsList(updatedList)
    console.log( 'similars updated..' )

    patchField( { key:'similar', value: updatedList.join('|') } )
  }

  const removeSimilar = (item) => {
    console.log(item)
    let updatedList = [...similarsList]
    updatedList.splice(updatedList.indexOf(item), 1)
    setSimilarsList(updatedList)
    patchField( { key:'similar', value: updatedList.join('|') } )
  }

  const extendSimilars = (newShow, inputRef) => {
    console.log('extendSimilars', newShow)
    let newSimilars = newShow
    let updatedList = [...similarsList]
    updatedList = newSimilars.map(s => sanitizeShowName(s)).concat(updatedList)
    let uniqueList = [...new Set(updatedList)];
    setSimilarsList(uniqueList)
    console.log( 'similars updated..' )
    patchField( { key:'similar', value: updatedList.join('|') } )
    console.log(patch)
    inputRef.current.clear();
  }


  const onClickDelete = async () => {
    const confirm = window.confirm("Are you sure you want to delete this show?");
    if(!confirm) return;
    let result = await showsService.deleteShow( show.id );
    if(result){
      // onShowsUpdated(show)
      toast.success("Show deleted successfully.");
      navigate("/admin");
    }
  }
  
/*
  const filterGenres = (input) => {
    return genres.filter(g => g.toLowerCase().includes(input.toLowerCase()))
  }

  const filterThemes = async (input) => {

    // sanitize input
    input = input.replace(/[^-A-Za-z0-9:!,+?&#'\\. ]/gi,'')
    // search term in theme taxonomy
    const res = await dataService.getTerms( { search: input, taxonomy: 'theme' } );
    console.log( res )
    if(!res) return [];

    const terms = res.data;
    return terms

    
  }*/

  const filterTerms = async (input, taxonomy) => {

    // sanitize input
    input = input.replace(/[^-A-Za-z0-9:!,+?&#'\\. ]/gi,'')
    // search term in theme taxonomy
    const res = await dataService.getTerms( { search: input, taxonomy } );
    console.log( res )
    if(!res) return [];

    const terms = res.data;
    return terms

    /*return terms.filter(g => g.toLowerCase().includes(input.toLowerCase()))*/
  }

  return ( 
    <>
      { show && 
        <div className="post">
          <Row> 
            <Col md={6}>
            <div>
              <div className="form-group mb-2">
                {show.slug && <small><a href={`${siteSettings.siteUrl}/${show.slug}`} target="new">{show.name}</a></small>}
                <DebounceInput 
                  id="name"
                  className="form-control"
                  debounceTimeout={400}
                  onChange={e => patchField({key:'name', value: e.target.value})}
                  value={show.name}
                  />
                  { !isNew && <div className="mb-2"><span className="xs">Last Updated: <Moment date={show.updated} fromNow /></span></div> }
              </div>

              <div className="form-group mb-2 flex">
                <div className="c1_2"><label htmlFor="year">Year</label>
                <DebounceInput 
                  id="year"
                  className="form-control"
                  debounceTimeout={400}
                  onChange={e => patchField({ key:'year', value: e.target.value })}
                  value={show.year}
                  /></div>
                <div className="c1_2"><label htmlFor="network">Network</label>
                <DebounceInput 
                  id="network"
                  className="form-control"
                  debounceTimeout={400}
                  onChange={e => patchField({ key:'network', value: e.target.value })}
                  value={show.network}
                  /></div>
              </div>
              <div className="mb-2"> 
                <TermsInput
                    placeholder="Genres.."
                    id="genres"
                    labelKey="name"
                    selectedInput={genresInput}
                    onSearch={ t => filterTerms(t, 'genre') }
                    taxonomy="genre"
                    onChange={t => setTerms(t, "genresInput")}
                  />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="summary">Summary</label>
                <DebounceInput 
                  id="summary"
                  className="form-control"
                  element="textarea"
                  rows="8"
                  debounceTimeout={1000}
                  onChange={e => patchField( { key:'summary', value: e.target.value } )}
                  value={show.summary}
                  />
              </div>
              
              <div className="form-group mb-2">
                <div>
                  <TermsInput
                    placeholder="Themes.."
                    id="themes"
                    labelKey="name"
                    minLength={3}
                    multiple
                    onSearch={ t => filterTerms(t, 'theme') }
                    selectedInput={ themesInput }
                    taxonomy="theme"
                    onChange={t => {setTerms(t, "themesInput");}}
                  />
                </div>
              </div>

              <div className="form-group mb-2">
                <div>
                  <TermsInput
                    placeholder="Sections.."
                    id="sections"
                    labelKey="name"
                    minLength={3}
                    multiple
                    onSearch={ t => filterTerms(t, 'section') }
                    selectedInput={ sectionsInput }
                    taxonomy="section"
                    onChange={t => {setTerms(t, "termsInput__section");}}
                  />
                </div>
              </div>
              
            </div>
          </Col>


          <Col md={6}>
          <div className="well">
            <div className="input-group mb-3">
              <ShowNameInput
                id="new-similars" 
                className="form-control" 
                placeholder="Search Show.." 
                aria-label="Similars" 
                parentRef={newSimilarsRef} 
                onChange={s => extendSimilars([s[0].label], newSimilarsRef)} />
              <ShowTaxInput
                id="new-similars-by-theme" 
                className="form-control" 
                placeholder="Search Theme.." 
                aria-label="Similars" 
                taxonomy="theme"
                parentRef={newSimilarsTaxRef} 
                onChange={st => extendSimilars([st[0].name], newSimilarsTaxRef)} />
            </div>
            <div className="px-3">
              <SimilarShowsList 
                items={similarsList} 
                onSortUpdate={updateSimilars}
                onRemoveItem={removeSimilar}
                onLaunchClick={item => window.open( `/admin/shows/${item.id}/edit`, '_blank' )} />
            </div>
          </div>
          </Col>


      </Row>

        <div id="toolbar" className="toolbar p-3">
          { isNew && <button className="btn float-end" onClick={onClickSave}>Save</button>}
          { show && show.id &&
            <button onClick={onClickDelete} className="btn btn-sm btn-delete">Delete Show</button>
          }
          { !isNew && <button className="btn float-end" onClick={onClickUpdate}>Update</button> }
        </div>
      </div>
      }
    </>
  );
}

export default ShowEditor;