import { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';

const TermsInput = ({taxonomy, selectedInput, onSearch, ...rest}) => {
  const initialTerms = selectedInput ? selectedInput.split(',') : [];

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initialOptions] = useState(initialTerms);

  const turnOffFilterBy = () => true;

  const handleSearch = async (query) => {
    setIsLoading(true)
    const terms = await onSearch(query)
    if(terms){
      console.log(terms)
      setOptions(terms)
      setIsLoading(false)
    }
  }

  return ( 
    <AsyncTypeahead
      filterBy={turnOffFilterBy}
      isLoading={isLoading}
      minLength={3}
      onSearch={handleSearch}
      multiple
      defaultSelected={initialOptions} 
      options={options}
      allowNew 
      {...rest}
    />
  );
}

export default TermsInput;