import siteSettings from "../config/site-settings"
import httpService from "./httpService"

const apiBase = siteSettings.apiUrl;


export const getTerms = async ( params ) => {
  let result = await httpService.get( apiBase + `/terms`, { params } );
  if(!result) return null;
  return result.data
}




const dataService = {
  getTerms,
};

export default dataService;


