import { createContext, useEffect, useMemo } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logger from "./services/logService";

import './App.css';
import Layout from './pages/Layout';
import NotFound from './pages/NotFound';
import Show from './pages/Show';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Register from './pages/Register';
import authService from './services/authService';
import Dashboard from './pages/Dashboard';
import NewShow from './pages/shows/NewShow';
import { useState } from 'react';
import { ProtectedLayout } from './pages/ProtectedLayout';
import { HomeLayout } from './pages/HomeLayout';
import EditShow from './pages/shows/EditShow';
import ForgotPassword from './pages/ForgotPassword';
import Shows from './pages/Shows';
import { ToastContainer } from 'react-toastify';

export const UserContext = createContext(null)

function App() {

  const [user, loading] = authService.useAuth()
  const [appUser, setAppUser] = useState(null) 

  useEffect(() => {
    if ( loading ) return;
    async function loadUserDetails(){
      // query app user details
      logger.log("App.getAppUser")
      setAppUser( { "user": user } )
      /*let appUser = await authService.getAppUser(user)
      if( appUser ){
        setAppUser( appUser )
      } else {
        logger.log("!!! app user get failed !!!")
        // authService.logout()
      }*/
    }

    if(user) loadUserDetails();
  }, [user, loading]);

  function onLoggedIn(user) {
    logger.log("userUpdated", user)
    //setUser(user)
  }

  const account = useMemo(() => {
		return { user, loading, appUser, onLoggedIn };
	}, [user, loading, appUser]);

  return (
    <UserContext.Provider value={account}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Layout /> } >
            <Route element={ <HomeLayout /> } >
              <Route index element={ <Login /> } />
              <Route path="/login" element={ <Login /> } />
              <Route path="/forgot-password" element={ <ForgotPassword /> } />
              <Route path="/register" element={ <Register /> } />
            </Route>

            <Route path="/shows" element={ <Shows /> } />
            <Route path="/shows/:id" element={ <Show /> } />

            <Route path="/admin" element={ <ProtectedLayout /> } >
              <Route index element={ <Dashboard /> } />
              <Route path="/admin/shows/:id/edit" element={ <EditShow /> } />
              <Route path="/admin/shows/new" element={ <NewShow /> } />
            </Route>
            <Route path="*" element={ <NotFound /> } />
            <Route path="/logout" element={ <Logout /> } />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </UserContext.Provider>
    
  );
}

export default App;
